import React from "react"
import { Link } from "gatsby"
import Container from "../components/common/Container"
import Layout from "./../components/layouts/Default"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Impressum" desc="">
      <Container>
        <div className="prose">
          <h1>Teilnahmebedingungen an Fotoexkursionen und Fotoworkshops</h1>
          <p>
            Die NATURBLICK Fotoexkursionen wendet sich nicht nur an alle
            naturverbundenen und fotografisch interessierten Personen, sondern
            auch an diejenigen, die Spaß an der Natur und deren Beobachtung
            haben.
          </p>

          <h3>Teilnahmevoraussetzungen</h3>

          <h4>Teilnahmegebühr</h4>
          <p>
            1. Die Teilnahmegebühr richtet sich nach dem ausgewiesenen Preis der
            ausgeschrieben und veröffentlicht wurde und beinhaltet die
            gesetzliche MwSt.
          </p>
          <p>
            2. Die Rechnungsstellung erfolgt unmittelbar nach der Anmeldung an
            den Teilnehmer oder die Person, die als Rechnungsempfänger angegeben
            wurde.
          </p>

          <h4>Anmeldung und Bezahlung</h4>
          <p>
            3. Voraussetzung für die Teilnahme ist zunächst einmal die
            persönliche Anmeldung. (auch telefonisch oder per E-Mail)
          </p>
          <p>
            4. Nach Ihrer Anmeldung erhalten Sie von uns eine Rechnung, die nach
            Begleichung der Anzahlung als Buchungsbestätigung gilt.
          </p>
          <p>
            5. Die Rechnung über die Teilnahmegebühr ist binnen zwei Wochen nach
            Rechnungsdatum eingehend beim NATURBLICK Verlag zu bezahlen,
            zuerst&nbsp;in Form einer Anzahlung in Höhe von 200 Euro/p. Person.
            Der Restbetrag muss spätestens 5 Wochen vor Beginn der Exkursion/des
            Workshops überwiesen&nbsp;werden. Eine nicht fristgerechte Zahlung
            befreit den Teilnehmer nicht von der Zahlungspflicht. Wird der
            fällige Exkursions-/Workshoppreis&nbsp;trotz Mahnung und
            angemessener Fristsetzung zur Zahlung nicht bezahlt, ist NATURBLICK
            Verlag berechtigt, vom Vertrag zurückzutreten (§ 323 BGB)&nbsp;und
            den Kunden mit Rücktrittskosten zu belasten, die sich an
            nachstehender Ziffer 9 orientieren, sofern der Teilnehmer nicht ein
            Recht zur Zahlungsverweigerung&nbsp;hatte.
          </p>
          <p>
            6. Eine kostenlose Stornierung der Anmeldung ist nur in
            schriftlicher Form an den NATURBLICK Verlag bis 8 Wochen vor Beginn
            der Exkursion/des&nbsp;Workshops möglich (eingehend beim NATURBLICK
            Verlag).
          </p>
          <p>
            7. Sollten mehr Anmeldungen eingehen, als Exkursions-/Workshopplätze
            verfügbar sind, entscheidet die zeitliche Reihenfolge der
            Anmeldungen.&nbsp;Bei mehreren gleichzeitig eingegangen Anmeldungen
            entscheidet unter Ausschluss des Rechtsweges das Los.
          </p>
          <h4>Kündigung der Teilnahme</h4>

          <p>
            8. Sie können die Teilnahme schriftlich an{" "}
            <span className="font-bold">
              NATURBLICK Verlag, Haubachweg 16, 40625 Düsseldorf
            </span>{" "}
            oder per E-Mail an{" "}
            <span className="font-bold">verlag@naturblick.com</span> kündigen.
          </p>
          <p>
            9. Bis 8 Wochen vor Beginn ist ein Rücktritt kostenlos möglich. Bei
            einem späteren Rücktritt werden folgende Stornokosten berechnet:
            <br />
            bis zum 55. Tag vor Beginn: 15 % des Teilnahmepreises;
            <br />
            ab 54. bis 35. Tag vor Beginn: 25 % des Teilnahmepreises;
            <br />
            ab 34. bis 20. Tag vor Beginn: 50 % des Teilnahmepreises;
            <br />
            vom 19. Tag bis einen Tag vor Beginn: 85 % des Teilnahmepreises;
            <br />
            beim Nichterscheinen: 100 % des Teilnahmepreises.
          </p>
          <p>
            10. Falls der Teilnehmer eine Ersatzperson zur gleichen Konditionen
            stellt, wird die Umbuchung ohne zusätzliche Kosten vorgenommen.
          </p>
          <h4>&nbsp;</h4>
          <h4>Leistungsumfang</h4>
          <p>
            11. Die Exkursions-/Workshopinhalte ergeben sich aus den jeweiligen
            Beschreibungen in der Ausschreibung.
          </p>
          <p>
            12. Der NATURBLICK Verlag behält sich vor, einen Exkursionstermin
            aufgrund nicht erreichter Mindestzahl von Teilnehmern oder bei einer
            gleichzeitigen Verhinderung bzw. Erkrankung des begleitenden
            Fotografen abzusagen.
          </p>
          <p>
            13. Die Teilnahme an Fotoexkursion/Fotoworkshop erfolgt auf eigene
            Gefahr.
          </p>
          <p>
            14. Kosten der Anreise zum jeweiligen Startpunkt der Fotoexkursion
            sind nicht durch die Anmeldegebühr gedeckt.
          </p>
          <p>
            15. Der NATURBLICK Verlag kann nicht für Schäden haftbar gemacht
            werden, es sei denn, dass der betreffende Schaden vorsätzlich oder
            grob fahrlässig durch den NATURBLICK Verlag verursacht wurde.
          </p>
          <p>
            16. Für die Durchführung wetterabhängiger Veranstaltungen übernimmt
            der NATURBLICK Verlag keine Gewähr.
          </p>
          <p>
            17. Für Fotoexkursionen stellen unsere Partner-Firmen
            Leihausrüstungen ggf. zur Benutzung durch mehrere Teilnehmer
            kostenlos zur Verfügung. Der Teilnehmer kann für einen verursachten
            Schaden an den Leihgeräten haftbar gemacht werden, wenn er einen
            Schaden vorsätzlich oder grob fahrlässig verursacht.
          </p>
          <p>
            18. Für eigene Ausrüstungen der Teilnehmer übernimmt NATURBLICK
            Verlag keine Haftung.
          </p>

          <h4>Vertragspartner und Gerichtsstand</h4>

          <p>19. Die Durchführung der Fotoexkursionen erfolgt durch</p>
          <p>
            <span className="font-bold">
              NATURBLICK Verlag, Peter Scherbuk, Haubachweg 16, 40625 Düsseldorf
              <br />
              Telefon: 0211.2 38 12 31
              <br />
              E-Mail:{" "}
              <a href="mailto:verlag@naturblick.com">verlag@naturblick.com</a>
              <br />
              <Link to="/">www.naturblick.com</Link>
              <br />
            </span>
            der auch Vertragspartner der Teilnehmer ist. Als Gerichtsstand wird
            Düsseldorf vereinbart.
          </p>
          <p>&nbsp;</p>
          <p>
            Mündliche Nebenabreden werden nicht getroffen. Vertragsänderungen
            bedürfen der Schriftform. Sollte eine der Bestimmungen in diesen
            Teilnahmebedingungen unwirksam sein oder werden, wird davon die
            Gültigkeit der übrigen Bestimmungen nicht berührt.
          </p>
          <p>
            Bitte teilen Sie uns unverzüglich mit, wenn Sie an der gebuchten
            Fotoexkursion nicht teilnehmen können, um nicht zuletzt auch Ihre
            Kosten zu sparen. Da unsere Gruppen recht klein sind und wir
            erfahrungsgemäß große Nachfrage erwarten, können wir unter Umständen
            noch zeitig uns um Ersatz bemühen.
          </p>

          <p>
            Wir empfehlen Ihnen eine Reiserücktrittsversicherung (z.B. im Fall
            einer Erkrankung etc.) abzuschließen, diese bekommen Sie z.B. in
            jedem Reisebüro.
          </p>
        </div>
      </Container>
    </Layout>
  )
}
